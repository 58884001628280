import React from "react";

import Header from "../../components/general/header/Header";
import Nav from "../../components/general/header/Nav";
import Register from "../../components/UI/account/Register";
import Footer from "../../components/general/footer/GeneralFooter";

const SignUp = () => {
  return (
    <>
      {/* <Header>
        <Nav />
      </Header> */}
      <Register />
      {/* <Footer /> */}
    </>
  );
};

export default SignUp;
