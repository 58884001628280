import React from "react";
import SignUpForm from "../../auth/SignUpForm";

const Register = () => {
  return (
    // <section className="d-flex justify-content-center align-content-center mt-5">
    //   <div className="container d-flex">
    //     <div className="col-6">
    //       <img src="images/login.jpg" alt="Login" className="img-fluid" />
    //     </div>
    //     <div className="col-6 d-flex flex-column">
    //       <h2 className="text-center">تسجيل الطالب</h2>
    <SignUpForm />
    //     </div>
    //   </div>
    // </section>
  );
};

export default Register;
