import React from "react";
import DeleteVideo from "../../../components/admin/videos/DeleteVideo";
const Delete = () => {
  return (
    <>
      <DeleteVideo />
    </>
  );
};

export default Delete;
