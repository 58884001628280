import React from "react";

import PreExam3D from "../../components/user/PreExam3D";

const PreExamD = () => {
  return (
    <>
      <PreExam3D />
    </>
  );
};

export default PreExamD;
