import React from "react";
import Header from "../../components/general/header/Header";
import Nav from "../../components/general/header/Nav";
import Account from "../../components/UI/account/Account";
import Footer from "../../components/general/footer/GeneralFooter";

const Signin = () => {
  return (
    <>
      {/* <Header>
        <Nav />
      </Header> */}
      <Account />
      {/* <Footer /> */}
    </>
  );
};

export default Signin;
