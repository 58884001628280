import React from "react";
import ShowExamResult from "../../components/user/ShowExamResult";

const PreExamResult = () => {
  return (
    <>
      <ShowExamResult />
    </>
  );
};

export default PreExamResult;
