import React from "react";
import Track3D from "../../components/track/Track3D";

const ShowTrack3D = () => {
  return (
    <>
      <Track3D />
    </>
  );
};

export default ShowTrack3D;
