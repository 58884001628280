import React from "react";
import UserChoose from "../../components/user/UserChoose";

const Choose = () => {
  return (
    <>
      <UserChoose />
    </>
  );
};

export default Choose;
