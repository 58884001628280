import React from "react";
import Delete from "../../../components/UI/library/Delete";

const DeleteLibrary = () => {
  return (
    <>
      <Delete />
    </>
  );
};

export default DeleteLibrary;
