import React from "react";
import EnrollTrack from "../../components/track/EnrollTrack";

const Enroll = () => {
  return (
    <>
      <EnrollTrack />
    </>
  );
};

export default Enroll;
