import React from "react";
import DeleteActiv from "../../../components/admin/activity/DeleteActiv";

const ActDelete = () => {
  return (
    <>
      <DeleteActiv />
    </>
  );
};

export default ActDelete;
