import React from "react";
// import Exam from "../../components/UI/userExam/Exam";
import PreExam2D from "../../components/user/PreExam2D";

const PreExam = () => {
  return (
    <>
      <PreExam2D />
    </>
  );
};

export default PreExam;
