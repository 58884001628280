import React from "react";
import Logout from "../components/auth/Logout";

const Signout = () => {
  return (
    <>
      <Logout />
    </>
  );
};

export default Signout;
